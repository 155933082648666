<div
	class="flexp-wrapper"
	[ngClass]="{
		'': !darkTheme.isEnabled$.value,
		__dark: darkTheme.isEnabled$.value
	}">
	<div
		class="top-header"
		[ngClass]="{
			'': !darkTheme.isEnabled$.value,
			__dark: darkTheme.isEnabled$.value
		}">
		<span>Flex Periods</span>

		<div class="top-right-header">
			<div class="flex-feedback-button">
				<app-gradient-button
					[size]="'medium'"
					[leftIcon]="'./assets/Feedback (Blue-Gray).svg' | resolveAsset"
					[gradient]="'#E2E5EA, #E2E5EA'"
					[textColor]="'#7083A0'"
					[cursor]="'pointer'"
					(buttonClick)="openIntercom()">
					<div>Feedback?</div>
				</app-gradient-button>
			</div>

			<div class="create-period-button" (click)="openCreateFlex()" data-px-id="flex--create-period">
				<img [src]="'./assets/Plus (White).svg' | resolveAsset" alt="Add Flex Period" />
				<span>Create Period</span>
			</div>
		</div>
	</div>
	<div *ngIf="showFlexAdminNux" style="position: absolute; margin: 70px 0 0 510px; z-index: 200">
		<app-nux-universal
			[description]="
				'“Session Snapshots” lets you see who has and hasn’t signed up for Flex activities. Use the arrows to view past and future sessions, along with related activities.'
			"
			(gotItEvent)="dismissNux()">
		</app-nux-universal>
	</div>
	<ng-container *ngIf="school?.attendance_access">
		<sp-attendance></sp-attendance>
	</ng-container>
	<app-flex-stats
		*ngFor="let period of flexPeriods"
		[flexPeriod]="period"
		[schoolId]="school.id"
		[numStudents]="studentCount"
		[timeZone]="school.timezone"
		(deleteFlexPeriod)="deletePeriod(period.id)">
	</app-flex-stats>
</div>

<ng-template #deleteDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove a flex period, activity sessions that have students in them will still exist, but new sessions or activities can't be created
	</p>
</ng-template>
