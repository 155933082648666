import { Injectable } from '@angular/core';
import { captureException, captureMessage } from '@sentry/angular';
import { $WebSocket } from 'angular2-websocket/angular2-websocket';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, publish, refCount, switchMap, tap } from 'rxjs/operators';
import { ExclusionGroup } from '../models/ExclusionGroup';
import { HallPass } from '../models/HallPass';
import { Invitation } from '../models/Invitation';
import { Pinnable } from '../models/Pinnable';
import { Request } from '../models/Request';
import { WaitingInLinePass } from '../models/WaitInLine';
import { HttpService } from './http-service';
import { Logger } from './logger.service';

interface SendMessage {
	action: string;
	data?: string;
}

interface RawMessage {
	type: string;
	data: RawMessageData;
}

interface RawMessageData {
	action: string;
	detail?: string;
	data?: EventData | Event;
	audible_event?: AudibleEvent;
}

export interface AudibleEvent {
	sound: string;
	room_ids: number[];
	kiosk_only: boolean;
	teacher_only: boolean;
}

export interface AttachedPictures {
	user_id: number;
	photo_url: string;
	is_photo_update: true;
}

export interface EventData {
	id?: string;
	type?: string;
	body?: string;
	subject?: string;
	sync_type?: string;
	utc_time?: string;
	attached_pictures?: AttachedPictures[];
}
export interface EncounterPreventionResponse {
	exclusion_pass: Record<string, any>;
	conflict_student_ids: number[];
	conflict_passes: HallPass[];
	exclusionGroups: ExclusionGroup[];
}
export interface PollingEvent {
	action: string;
	detail?: string;
	data?: EventData | Pinnable | HallPass[] | HallPass | Request | Invitation | WaitingInLinePass | Location | EncounterPreventionResponse;
	audible_event?: AudibleEvent;
}

function doesFilterMatch(prefix: string, path: string): boolean {
	const prefixParts: string[] = prefix.split('.');
	const pathParts: string[] = path.split('.');

	if (prefixParts.length > pathParts.length) {
		return false;
	}

	for (let i = 0; i < prefixParts.length; i++) {
		if (prefixParts[i] !== pathParts[i]) {
			return false;
		}
	}

	return true;
}

@Injectable({
	providedIn: 'root',
})
export class PollingService {
	private eventStream: Subject<PollingEvent> = new Subject();
	private rawMessageStream: Subject<RawMessage> = new Subject();

	private sendMessageQueue$: Subject<SendMessage> = new Subject();
	private websocket: $WebSocket = null;

	isConnected$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	private failedHeartbeats = 0;
	private lastHeartbeat = Date.now() + 30 * 1000;
	private hasConnectionError = false;

	constructor(private http: HttpService, private _logger: Logger, private cookie: CookieService) {
		this.connectWebsocket();
		this.createEventListener();
		this.listenForHeartbeat();
		this.listenForAuthentication();
		this.createOnlineListener();
		setTimeout(() => {
			this.checkForHeartbeat();
		}, this.getHeartbeatTime());
	}

	private connectWebsocket(): void {
		if (this.websocket !== null) return;
		const spCookie = this.cookie.get('smartpassToken');
		if (!spCookie) {
			return;
		}
		const school = this.http.getSchool();
		if (!school) {
			return;
		}

		const serverFromStorage = this.http.getServerFromStorage();
		if (!serverFromStorage) {
			return;
		}
		const { server } = serverFromStorage;
		let sendMessageSubscription: Subscription = null;

		const url = server.ws_url;
		const ws = new $WebSocket(url, null, {
			reconnectIfNotNormalClose: false,
		});
		console.log('Websocket created');
		this.websocket = ws;

		let opened = false;
		ws.onOpen(() => {
			if (this.websocket !== ws) return;
			opened = true;
			this.hasConnectionError = false;

			console.log('Websocket opened');
			const auth = {
				action: 'authenticate',
				token: spCookie,
				token_type: 'cookie_value',
				school_id: school.id,
			};

			ws.send4Direct(JSON.stringify(auth));

			// any time the websocket opens, trigger an invalidation event because listeners can't trust their
			// current state but by refreshing and listening from here, they will get all updates. (technically
			// there is a small unsafe window because the invalidation event should be sent when the authentication
			// success event is received)
			this.rawMessageStream.next({
				type: 'message',
				data: {
					action: 'invalidate',
					data: null,
				},
			});

			if (sendMessageSubscription !== null) {
				sendMessageSubscription.unsubscribe();
				sendMessageSubscription = null;
			}
			sendMessageSubscription = this.sendMessageQueue$.subscribe((message) => {
				ws.send4Direct(JSON.stringify(message));
			});
		});

		setTimeout(() => {
			if (!opened) ws.close(true);
		}, 5000);

		ws.onMessage(async (event) => {
			// send websocket data to sentry for certain schools/urls
			// const sentryMatch = SENTRY_WEBSOCKETS_LIST.find(
			// 	(s) => window.location.href.includes(s.urlMatch) && this.http.currentSchoolSubject.getValue()?.id === s.schoolId
			// );
			// if (sentryMatch) {
			// 	const { getCurrentHub } = await import('@sentry/browser');
			// 	getCurrentHub().addBreadcrumb({
			// 		category: 'websocket',
			// 		message: event.data.action,
			// 		data: JSON.parse(event.data),
			// 		level: 'info',
			// 	});
			// }
			const { getCurrentHub } = await import('@sentry/browser');
			const wsData: PollingEvent = JSON.parse(event.data);
			const { action, detail, data, audible_event } = wsData;
			getCurrentHub().addBreadcrumb({
				category: 'websocket',
				message: action,
				data: {
					detail,
					data,
					audible_event,
					school_id: this.http.currentSchoolSubject.getValue()?.id,
				},
				level: 'info',
			});
			this.rawMessageStream.next({
				type: 'message',
				data: JSON.parse(event.data),
			});
		});

		ws.onError((event) => {
			if (event instanceof ErrorEvent) {
				captureException(event.error, {
					extra: {
						message: event.message,
					},
				});
			} else {
				captureException(event, {
					extra: {
						serialized: JSON.stringify(event),
					},
				});
			}
			this.hasConnectionError = true;
			// only add to failedHeartbeats if there aren't already any
			// otherwise multiple errors will add too many and bump
			// the exponential backoff too much
			if (!this.failedHeartbeats) {
				this.failedHeartbeats += 1;
			}
			this.rawMessageStream.next({
				type: 'error',
				data: event,
			});
		});

		/* This observable should never complete, so the following code has been disabled.

      // we can't use .onClose() because onClose is triggered whenever the internal connection closes
      // even if a reconnect will be attempted.
      ws.getDataStream().subscribe(() => null, () => null, () => {
        s.complete();
      });
       */

		ws.onClose((event: CloseEvent) => {
			if (!event.wasClean) {
				captureException(event, {
					extra: {
						code: event.code,
						reason: event.reason,
						wasClean: event.wasClean,
					},
				});
			} else {
				captureMessage('websocet closed cleanly', {
					extra: {
						code: event.code,
						reason: event.reason,
						wasClean: event.wasClean,
						serialized: JSON.stringify(event),
					},
				});
			}

			if (sendMessageSubscription !== null) {
				sendMessageSubscription.unsubscribe();
				sendMessageSubscription = null;
			}
			this.websocket = null;
		});
	}

	private disconnectWebsocket(): void {
		if (this.websocket === null) return;

		this.websocket.close(true);
		this.websocket = null;
	}

	refreshHeartbeatTimer(): void {
		this.failedHeartbeats = 0;
		this.connectWebsocket();
	}

	private createEventListener(): void {
		this.rawMessageStream
			.pipe(
				tap((event: RawMessage) => {
					if (event.type !== 'message') {
						this._logger.error(event);
					}
				}),
				filter((event: RawMessage) => event.type === 'message'),
				map((event: RawMessage) => event.data)
			)
			.subscribe((event: PollingEvent) => {
				this.eventStream.next(event);
			});
	}

	private createOnlineListener(): void {
		fromEvent(window, 'online').subscribe(() => {
			this.refreshHeartbeatTimer();
		});
		fromEvent(window, 'offline').subscribe(() => {
			this.isConnected$.next(false);
			this.disconnectWebsocket();
		});
	}

	listen(filterString?: string): Observable<PollingEvent> {
		if (filterString) {
			return this.eventStream.pipe(
				publish(),
				refCount(),
				filter((e) => doesFilterMatch(filterString, e.action))
			);
		} else {
			return this.eventStream.pipe(publish(), refCount());
		}
	}

	listenOnCurrentSchool(filterString?: string): Observable<PollingEvent> {
		if (!this.http.getSchool()?.id) {
			return this.listen(filterString);
		}

		if (filterString) {
			return this.eventStream.pipe(
				publish(),
				refCount(),
				filter((e) => doesFilterMatch(filterString, e.action) && e?.data?.school_id === this.http.getSchool()?.id)
			);
		} else {
			return this.eventStream.pipe(
				publish(),
				refCount(),
				filter((e: any) => {
					if (e?.data?.school_id) {
						return e?.data?.school_id === this.http.getSchool()?.id;
					} else {
						return e;
					}
				})
			);
		}
	}

	sendMessage(action: string, data?: string): void {
		this.sendMessageQueue$.next({ action, data });
	}

	private getHeartbeatTime(): number {
		if (this.failedHeartbeats == 0 && !this.hasConnectionError) {
			//console.log('returning NORMAL 20000');
			return 20 * 1000;
		}
		// This returns an exponential backoff with a random value added,
		// in case every user gets disconnected at the same time (like when
		// a backend deployment causes a general disconnect).
		// The number returned will start at 1000 milliseconds, and then increase exponentially by
		// the power of 2, with a random number of milliseconds below 1000 added.
		// This will decrease the load on the server if everyone is trying to reconnect at once,
		// because their connections will be slightly staggered.
		// Note: if issues are seen in the future, may want to increase the random number added
		// (change Math.random() * 1000 to Math.random() * 2000, for example).
		// This will max out at 30 seconds and stop incrementing the value.
		// console.log('returning EXPONENTIAL 20000',Math.min(Math.pow(2, this.failedHeartbeats) * 1000 + Math.floor(Math.random() * 1000), 30000));
		return Math.min(Math.pow(2, this.failedHeartbeats) * 1000 + Math.floor(Math.random() * 1000), 30000);
	}

	private listenForHeartbeat(): void {
		this.listen('heartbeat').subscribe(() => {
			this.lastHeartbeat = Date.now();
		});
	}

	private listenForAuthentication(): void {
		this.listen('authenticate.success').subscribe(() => {
			this.isConnected$.next(true);
		});
	}

	private checkForHeartbeat(): void {
		if (this.lastHeartbeat < Date.now() - 20000 || this.hasConnectionError) {
			this.isConnected$.next(false);
			this.failedHeartbeats += 1;
			this.disconnectWebsocket();
			this.connectWebsocket();
		} else {
			this.failedHeartbeats = 0;
		}

		setTimeout(() => {
			this.checkForHeartbeat();
		}, this.getHeartbeatTime());
	}

	restartOnConnected() {
		return <T>(source: Observable<T>): Observable<T> =>
			this.isConnected$.pipe(
				filter(Boolean),
				switchMap(() => source)
			);
	}
}
